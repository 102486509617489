import React, { useState } from "react";

const NewPatientPortal = () => {
  // State to manage the entered password
  const [password, setPassword] = useState("");
  // State to manage whether the password is correct
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  // Function to handle password submission
  const handlePasswordSubmit = () => {
    // Replace 'JWPT546' with the actual password
    if (password === "JWPT!") {
      setIsPasswordCorrect(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  // Function to handle key press events (e.g., Enter key)
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  // JSX structure based on the password correctness
  return (
    <div>
      {isPasswordCorrect ? (
        // Content to display when the password is correct
        <div className="container taper-body-info">
          <div className="center-item-container">
            <div className="taper-body-info">
              <h1>JointWorks Physical Therapy - New Patient Forms</h1>
              {/* If you want to add or amend the below PDF you need to do the uc?id= and then the rest of the link from the google doc. Remove /preview if it appears at the end */}
              <a
                href="https://drive.google.com/uc?id=1-xss-w1AkeM6twDjWZaD1aPrnxNzCCf5"
                download="JWPT_Patient_Intake_Forms.pdf"
              >
                <h2>Patient Intake Forms (Mandatory)</h2>
              </a>

              <a
                href="https://drive.google.com/uc?id=1a1q8YxrEA3e14KKqs4YeQ7dVbRpRDdgY"
                download="JWPT_Patient_Intake_Forms.pdf"
              >
                <h2>Notice of Privacy Practices (Optional)</h2>
              </a>

              <a
                href="https://drive.google.com/uc?id=1gm8gaiXDKS9q079uFVgKZjtpZDNLXKJS"
                download="JWPT_Patient_Intake_Forms.pdf"
              >
                <h2>Telehealth Consent Form (Optional)</h2>
              </a>

              <a
                href="https://drive.google.com/uc?id=1z_rjHkDbYn-D7WpYHxaChbmbqIqtE4P9"
                download="JWPT_Patient_Intake_Forms.pdf"
              >
                <h2>Authorization to Use and Disclose PHI (Optional)</h2>
              </a>
            </div>
          </div>
        </div>
      ) : (
        // Content to display when the password is incorrect
        <div className="container taper-body-info">
          <div className="center-item-container">
            <div className="taper-body-info">
              <h1>New Patient Portal</h1>
              <p>
                To access the New Patient Portal, please input the password
                supplied by Dr. Lagasse.
              </p>
              <p>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  *IMPORTANT*
                </span>
                Upon entering the password, the Patient Intake Form PDF will
                automatically download to your computer. Kindly complete the
                forms, save the file, and send it via email to
                Dr.Lagasse@JointWorksPT.com - thank you!
              </p>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <br />
              <button onClick={handlePasswordSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPatientPortal;

//Other PDFs

// <h1>Notice of Privacy Practices (Review Only)</h1>
//           <iframe
//             title="Notice of Privacy Practices"
//             src={`https://drive.google.com/uc?id=1a1q8YxrEA3e14KKqs4YeQ7dVbRpRDdgY`}
//             width="100%"
//             height="600px"
//           ></iframe>

//           <h1>Telehealth Consent Form (Optional Form)</h1>
//           <iframe
//             title="Telehealth Consent Form"
//             src={`https://drive.google.com/uc?id=1gm8gaiXDKS9q079uFVgKZjtpZDNLXKJS`}
//             width="100%"
//             height="600px"
//           ></iframe>

//           <h1>Authorization to Use Information (Optional Form)</h1>
//           <iframe
//             title="Authorization to Use and Disclose PHI"
//             src={`https://drive.google.com/uc?id=1z_rjHkDbYn-D7WpYHxaChbmbqIqtE4P9`}
//             width="100%"
//             height="600px"
//           ></iframe>
