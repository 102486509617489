import React, { useState } from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [hearAbout, setHearAbout] = useState("");
  const [comments, setComments] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = "New Patient Consultation Request";
    const body = `Thank you for contacting JointWorks Physical Therapy! We appreciate your interest in our services. 

For your convenience, the details you provided in the form have been copied below. Please feel free to send this email as it is or add any additional information you'd like to share.

Name: ${name}
Email: ${email}
Phone: ${phone}
Preferred Date: ${date}
Preferred Time: ${time}
How did you hear about us? ${hearAbout}
Additional Comments: ${comments}

We look forward to getting in touch with you soon to discuss your needs and answer any questions you may have. If you require immediate assistance or have any urgent concerns, please don't hesitate to reach out to us directly.

Warm regards,
Dr. Steven Lagasse
JointWorks Physical Therapy
`;

    window.location.href = `mailto:Dr.Lagasse@JointWorksPT.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    console.log("Form submitted");
    // Perform any other success actions
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 className="center-item-container">Consultation Form</h2>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            id="name"
            onChange={(e) => setName(e.target.value)}
            required
            type="text"
            value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            type="email"
            value={email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone *</label>
          <input
            id="phone"
            onChange={(e) => setPhone(e.target.value)}
            required
            type="tel"
            value={phone}
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">Preferred Date</label>
          <input
            id="date"
            onChange={(e) => setDate(e.target.value)}
            type="date"
            value={date}
          />
        </div>
        <div className="form-group">
          <label htmlFor="time">Preferred Time</label>
          <input
            id="time"
            onChange={(e) => setTime(e.target.value)}
            type="time"
            value={time}
          />
        </div>
        <div className="form-group">
          <label htmlFor="hearAbout">How did you hear about us?</label>
          <input
            id="hearAbout"
            onChange={(e) => setHearAbout(e.target.value)}
            type="text"
            value={hearAbout}
          />
        </div>
        <div className="form-group">
          <label htmlFor="comments">Additional Comments</label>
          <textarea
            id="comments"
            onChange={(e) => setComments(e.target.value)}
            value={comments}
          ></textarea>
        </div>
        <button className="body-button" type="submit">
          Send Email
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
