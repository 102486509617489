import React from "react";
import "./NoSuprisesAct.css";

const NoSurprisesAct = () => {
  return (
    <>
      <h1>Good Faith Estimate</h1>
      <div className="taper-body-info">
        <div className="paragraph-container">
          <span className="increase-font-size">
            <strong>JointWorks Physical Therapy, PLLC</strong>
          </span>
          <br />
          <span className="increase-font-size">
            <strong>Patients’ Rights Notice - Your Cost of Care</strong>
          </span>
          <br />

          <p className="">
            <strong>
              Under the federal No Surprises Act, we are required to inform of
              the following rights you have regarding advance notice of the out
              of pocket costs you may incur if you are uninsured, or if you have
              non- governmental health insurance coverage.
            </strong>
          </p>
          <p>
            Upon the scheduling of any non-emergency encounter, or upon your
            request, we will disclose whether we participate in your health
            insurance plan.
          </p>
          <p>
            Should we be unable to quote a specific amount due the inability to
            predict in advance your specific treatment needs or diagnosis, we
            will disclose an estimate of the out of pocket costs you will be
            required to pay.
          </p>
          <p>
            If we do not participate in your health insurance plan, or if you
            choose not to use your health insurance for care rendered by our
            practice, or if you are uninsured, you have a right to receive a
            written disclosure of the charges you will be responsible to pay us
            which will be provided to you via a written Good Faith Estimate of
            your expected out of pocket health care costs within the following
            time frames:
          </p>
          <ul>
            <li>
              If you schedule your appointment at least 10 business days in
              advance: within 3 business days after scheduling.
            </li>
            <br />
            <li>
              If you schedule your appointment at least 3 business days in
              advance: within 1 business day after scheduling.
            </li>
          </ul>
          <p>
            You also have the right to request a Good Faith Estimate in writing
            within 3 business days of request, even if your visit will be
            covered by your non-governmental health insurance plan. If we are
            unable to tell you a specific amount (because we cannot predict what
            specific treatment will be needed), we will disclose to you the
            estimated maximum amount that you will pay.
          </p>
          <p>
            You have a right to dispute a bill from our office if it is at least
            $400 more than the Good Faith Estimate we have provided you. For any
            questions or more information about your rights under the No
            Surprises Act, visit{" "}
            <a href="https://www.cms.gov/nosurprises/consumers">
              www.cms.gov/nosurprises/consumers
            </a>
            . or call 1-800-985-3059.
          </p>
        </div>
      </div>
    </>
  );
};

export default NoSurprisesAct;
