import React, { useState, useEffect } from "react";
import "./Payment.css";
import AMEX from "../images/AMEX.png";
import Venmo from "../images/Venmo.jpeg";

const Payment = () => {
  const defaultVenmoLink = "https://account.venmo.com/u/jointworkspt";
  const mobileVenmoLink = "https://www.paypal.com/qrcodes/venmocs/7895c683-96cf-438c-97be-41e1e40e92a5?created=1698442226";

  const [venmoLink, setVenmoLink] = useState(defaultVenmoLink);

  useEffect(() => {
    const updateLink = () => {
      if (window.innerWidth < 730) {
        setVenmoLink(mobileVenmoLink);
      } else {
        setVenmoLink(defaultVenmoLink);
      }
    };

    // Initial call to set the link based on the current window size
    updateLink();

    // Add the event listener for window resizing
    window.addEventListener("resize", updateLink);

    // Cleanup - remove the event listener when the component is unmounted
    return () => window.removeEventListener("resize", updateLink);
  }, []); // Empty dependency array ensures this useEffect runs once on mount and cleanup on unmount

  return (
    <div className="taper-body-info">
      <div className="payment-container payment-component">
        <h1>Payment Portal</h1>
        <p>
          <h3>Ready to make a payment?</h3>
          We've partnered with trusted brands
          <span className="brand"> American Express </span> and
          <span className="brand"> Venmo </span>
          to ensure a seamless and secure transaction.
          <br />
          Your financial security is our top priority.
        </p>
        <br />
        <div className="payment-link-container">
          <a
            href="https://pay-accept.americanexpress.com/JointWorksPT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="body-button">Pay with American Express</button>
            <img className="payment-img" src={AMEX} alt="American Express Logo" />
          </a>
        </div>
        <br />
        <a
          href={venmoLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="body-button">
            Scan or Click to Pay with Venmo
          </button>
          <img className="payment-img" src={Venmo} alt="Venmo QR Code" />
        </a>
      </div>
    </div>
  );
};

export default Payment;
