import React from 'react'

import './Steven.css'
import SJL from '../images/SJL.png'

const Steven = () => {
  return (
    <>
      <h1>Dr. Steven Lagasse</h1>
      <div className="taper-body-info">
        <div className="center-image-container">
          <img className="steve-face" alt="" src={SJL} />
        </div>
        <p className="bolded-titles">
          Steven is a Board-Certified Clinical Specialist in Orthopedic Physical
          Therapy who is passionate about helping people improve their quality
          of life through physical therapy.
        </p>
        <div className="center-image-container">
          <a className="body-button" href="/services">
            Learn more about services
          </a>
        </div>
        <h3 className="bolded-titles">Professional Background</h3>

        <p>
          Steven grew up in Northbridge, MA, and developed a passion for health
          and fitness at a young age. He earned his Bachelor of Science in
          Exercise Physiology and Health Fitness from Bridgewater State
          University in 2016. While at Bridgewater, he worked as a personal
          trainer and competed as a Bodybuilder and Olympic Weightlifter. After
          graduating, he went on to earn his Doctorate of Physical Therapy from
          the University of Connecticut in 2019.
        </p>
        <p>
          Steven completed his residency training at the Virginia Orthopedic
          Manual Physical Therapy Institute, where he specialized in orthopedic
          physical therapy. He also served as an adjunct faculty member at
          Shenandoah University&apos;s physical therapy program, where he assisted
          with didactic coursework and served as a clinical instructor for
          student internships.
        </p>
        <p>
          After completing his residency, Steven moved back to his native
          Massachusetts to work at Brigham and Women&apos;s Hospital. At Brigham and
          Women&apos;s, Steven treated patients of all ages with a variety of
          orthopedic conditions, including conservative management of orthopedic
          injuries, post-surgical rehabilitation, and chronic pain. During his
          time at Brigham and Women&apos;s, he was awarded his certifications as a
          Manual Physical Therapist through The North American Institute of
          Orthopedic Manual Therapy, and as a Board-Certified Clinical
          Specialist in Orthopedic Physical Therapy by the American Board of
          Physical Therapy Specialties.
        </p>
        <p>
          Steven is committed to providing his patients with the highest quality
          care. He takes the time to listen to their concerns and develop
          individualized treatment plans that are tailored to their specific
          needs. He is also a strong advocate for his patients and works closely
          with them to achieve their goals. He endeavors to provide a level of
          quality and expertise that exceeds that of traditional physical
          therapy companies, and achieves this through offering private
          one-on-one concierge physical therapy services.
        </p>
      </div>
    </>
  )
}

export default Steven
