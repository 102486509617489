import React from 'react'

const Flyer = () => {
  return (
    <>
      <h1>Welcome to JointWorks Physical Therapy!</h1>

      <div className="center-item-container">
        {/* <img className="change-logo-size" src={clinic} alt="" /> */}
      </div>
      <div className="taper-body-info">
        <div className="paragraph-container">
          <p>
        We provide eclectic, evidence-based physical therapy services to
        help patients in need.
          </p>
          <p>
        At JointWorks, we prioritize your well-being and offer direct
        access, allowing you to receive timely care without a physician&apos;s
        referral. As a self-pay practice, we prioritize the therapeutic
        alliance between our therapists and patients, free from the
        constraints of insurance companies. Experience personalized care
        that is not delayed or denied.
          </p>
          <p>
        Take a moment to explore our website and consider scheduling a free
        consultation with Dr. Steven Lagasse, our skilled physical
        therapist.
          </p>
        </div>

        <div className="center-item-container">
          <h3 className="underline-text">
        Your journey to optimal function starts here!
          </h3>
        </div>
        <br />
        <div className="center-item-container">
          <a className="body-button" href="/about">
        Learn more about JointWorks PT
          </a>
        </div>
      </div>
    </>
  )
}

export default Flyer