import React from "react";
import Insurance from "../images/Insurance.jpg";
import "./InsuranceInfo.css";

const InsuranceInfo = () => {
  return (
    <>
      <h1>Pricing and Insurance Overview</h1>
      <div className="container taper-body-info">
        <div className="center-item-container">
          <img alt="" className="insurance-img" src={Insurance} />
        </div>
        <div className="taper-body-info">
          <div className="paragraph-container">
            <p>
              At JointWorks, we prioritize personalized care and transparent
              pricing. Each session is competitively priced, reflecting our
              commitment to quality care.
            </p>
            <p>
              While we are a self-pay practice, we can provide a detailed
              superbill with all necessary information for potential insurance
              reimbursement. It's advisable to discuss this with your insurance
              provider before your appointment. Please note that managing the
              refund process will be your responsibility.
            </p>
            <div className="paragraph-price-container">
              <hr />
              <p>
                <h2 className="black-underline">
                  Price Per Individual Session
                </h2>
                <span>
                  <strong>60-minute Initial Evaluation:</strong>
                </span>{" "}
                $190
              </p>
              <p>
                <span>
                  <strong>60-minute Follow-up Session:</strong>
                </span>{" "}
                $190
              </p>
              <p>
                <span>
                  <strong>45-minute Dry Needling Session:</strong>
                </span>{" "}
                $120*
              </p>

              <i>
                *Please be aware that a superbill cannot be issued for dedicated
                dry needling sessions.
              </i>
              <h2 className="black-underline">Package Deals</h2>

              <div className="pricing-margin">
                <p>
                  <strong className="promotion-price-15">
                    Essential Package:
                  </strong>{" "}
                  <span className="discounted">$760</span> - Now $684 (Save 10%)
                </p>
                <i>Includes Initial Eval + 3 Follow-up Sessions (1x/week)</i>
              </div>

              <div className="pricing-margin">
                <p>
                  <strong className="promotion-price-10">
                    Comprehensive Package:
                  </strong>{" "}
                  <span className="discounted">$1,520</span> - Now $1,292 (Save
                  15%)
                </p>
                <i>Includes Initial Eval + 7 Follow-up Sessions (1x/week)</i>
              </div>

              <div className="pricing-margin">
                <p>
                  <strong className="promotion-price-20">
                    Accelerated Recovery Package:
                  </strong>{" "}
                  <span className="discounted">$3,040</span> - Now $2,432 (Save
                  20%)
                </p>
                <i>Includes Initial Eval + 15 Follow-up Sessions (2x/week)</i>
              </div>

              {/* <div className="pricing-margin">
                  <p>
                    <strong className="bfr-price">Dry Needling Package:</strong>{" "}
                    <span className="discounted">$450</span> - Now $405 (Save
                    10%)
                  </p>
                  <i>
                    Includes Initial Eval + 3 Dry Needling Sessions (1x/week)
                  </i>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceInfo;
