import React from 'react';
import HamburgerBlue from '../images/vectors/hamburger_blue.png';
import HamburgerGreen from '../images/vectors/hamburger_green.png';
import './HamburgerIcon.css';

const HamburgerIcon = ({ toggle }) => {
  return (
    <div className="hamburger" onClick={toggle}>
      <img className="burger green-burger" src={HamburgerGreen} alt="Hamburger Icon Green" />
      <img className="burger blue-burger" src={HamburgerBlue} alt="Hamburger Icon Blue" />
    </div>
  );
}

export default HamburgerIcon;
