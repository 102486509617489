import React from "react";
// import CopyrightFooter from "./CopyrightFooter";
// import PreFooter from "./PreFooter";
import "./TestimonialList.css";

const testimonialsData = [
  {
    id: 1,
    name: "Emily M.",
    testimonial: `"Steve was wonderful to work with at BWH. This was my first experience in over a decade with physical therapy, so I was nervous of the progress I would make when I was first referred for upper back pains. Steve took the time to go over the long list of pains I was having and slowly worked with me through each one to determine the root of the issue and a plan to reduce those discomforts. After a couple visits working together, I was already beginning to see improvement in my back. For PT to be successful, it definitely requires commitment outside of the clinic, but he provided me with all of the tools to do that successfully. Would be happy to work with Steve again!"`,
  },
  {
    id: 2,
    name: "Brendan D.",
    testimonial: `"I've been working with Steven on and off since I injured my lower back while weightlifting. It was brutal – intense muscle spasms and struggling to get up from a chair without my back locking up. I was even considering a trip to the hospital, but Steven was there to reassure me and offer advice based on my symptoms. He showed me specific exercises that slowly but surely helped me feel like my old self again over a few weeks. Although I still have the occasional back tweak, Steven is my go-to guy for quick recovery. Can't thank him enough for always having my back!"`,
  },
  {
    id: 1,
    name: "Darlene D.",
    testimonial: `"The initial consult with Steven immediately instilled confidence in my success. He is well versed in therapeutic exercises related to my needs and adjusted them at each visit. The added shared videos and printouts as well as coaching by example, emphasized the collaborative ethos. 
    I wholeheartedly recommend Steven Lagasse; his enthusiasm is contagious."`,
  },
  {
    id: 2,
    name: "Alvan F.",
    testimonial: `"I had a total knee replacement and post-operative Physical Therapy for 8 weeks. With Steve, he was very upbeat and encouraging at each session. He showed an interest in my goals and worked through my stiffness, increasing my range of motion, so I was able to meet the goal of walking, standing, sitting, climbing stairs, and descending stairs. I cannot express enough how well this worked for me. Thank you, Steve! At 84 years old, to have my movement back to normal, this is so great. I highly recommend Steve's professional ability."`,
  },
  {
    id: 1,
    name: "Vivan M.",
    testimonial: `"I worked with Steve following my total hip replacement. He is both professional and personable. He's very knowledgeable and was a pleasure to work with. I give him 5 stars and am very thankful for all the help he provided."`,
  },
  {
    id: 2,
    name: "Rosemary C.",
    testimonial: `"Steven consistently offered support and encouragement throughout our time together. His genuine willingness to listen and address my concerns was deeply appreciated. Many thanks, Steven."`,
  },
  {
    id: 1,
    name: "Hillary R.",
    testimonial: `"I began working with Steve following an injury while participating in a couch to 5K program. His professionism, compassion and willingness to take the time to explain things was encouraging. I recommend Steve's services for physical therapy and dry needling."`,
  },
  {
    id: 2,
    name: "Janice D.",
    testimonial: `"I would recommend Steve to anyone in need of physical therapy. He is one of the best I have ever seen. Not only is he dedicated, he is accurate, and he also works as a teacher along the way, which makes an amazing difference in understanding why and how your body works and heals."`,
  },
  {
    id: 2,
    name: "David D.",
    testimonial: `"I've been suffering from acute neck pain and increased frequencies of headaches for over a year, all due to a motor vehicle accident years ago. It was recommended that I attend physical therapy, and this is where I met Steve Lagasse. I can say now, and will continue to say, that Steve was outstanding in relieving me of my neck and headache pain. Initially, I thought physical therapy sessions would be limited to stationary bicycles or other typical gym exercises. But Steve introduced several new methods, including spinal manipulation and trigger points therapy among others. Techniques like band stretches were the key to me becoming pain-free! My strong recommendation for anyone in need of physical therapy is to contact Steve. He was the total difference in my recovery!"`,
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials">
      {testimonialsData.map((testimonial) => (
        <div key={testimonial.id} className="testimonial">
          <h3 className={testimonial.id % 2 === 1 ? "name odd" : "name even"}>
            {testimonial.name}
          </h3>
          <p className="content">{testimonial.testimonial}</p>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
