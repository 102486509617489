import React from "react";

import "./PrivacyPractices.css";

const PrivacyPractices = () => {
  return (
    <div>
      <h1>NOTICE OF PRIVACY PRACTICES</h1>
      <h2>
        HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT OF 1996 (HIPAA)
      </h2>

      <p>
        THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND
        DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW
        IT CAREFULLY.
        <strong>
          "THIS NOTICE APPLIES TO THE PRIVACY PRACTICES OF JOINTWORKS PHYSICAL
          THERAPY, PLLC, WHICH IS A COVERED ENTITY FOR HIPAA PURPOSES."
        </strong>
      </p>
      <p>
        The entities above are collectively referred to herein as JointWorks
        Physical Therapy. We are committed to the protection of your health
        information created, and/or maintained by JointWorks Physical Therapy,
        including any information that we receive from other health care
        providers or facilities.
      </p>
      <p>
        We at JointWorks Physical Therapy pledge to provide you with quality
        health care, and to have a relationship with you that is built on trust.
        This trust includes our commitment to respect the privacy and
        confidentiality of your health information. JointWorks Physical Therapy
        maintains your records for the time period required by law. Once the
        retention period has expired, JointWorks Physical Therapy destroys the
        records. If you have any questions regarding the retention period of
        your records, please contact the JointWorks Physical Therapy Privacy
        Officer (See contact information at the end of this Notice.)
      </p>
      <h2>YOUR RIGHTS</h2>
      <p>
        When it comes to your health information you have certain rights. This
        section explains your rights and some of our responsibilities to help
        you.
      </p>
      <h3>Get an electronic or paper copy of your medical record</h3>
      <ul>
        <li>
          You can ask to get an electronic or paper copy of your medical record
          and other health information we have about you. Ask us how to do this.
        </li>
        <li>
          We will provide a copy or a summary of your health information,
          usually within 30 days of your request. We may charge a reasonable,
          cost-based fee.
        </li>
      </ul>
      <h3>Ask us to correct your medical record</h3>
      <ul>
        <li>
          You can ask us to correct health information about you that you think
          is incorrect or incomplete. Ask us how to do this.
        </li>
        <li>
          We may say “no”, but we’ll tell you why in writing within 60 days.
        </li>
      </ul>
      <h3>Request confidential communications</h3>
      <ul>
        <li>
          You can ask us to contact you in a specific way (for example, home or
          office phone) or to send mail to a different address
        </li>
        <li>We will say “yes” to all reasonable requests.</li>
      </ul>
      <h3>Ask us to limit what we use or share</h3>
      <ul>
        <li>
          You can ask us not to use or share certain health information for
          treatment, payment, or our operations. We are not required to agree to
          your request, and we may say “no” if it would affect your care.
        </li>
        <li>
          If you pay for a service or health care item out-of-pocket in full,
          you can ask us not to share that information for the purpose of
          payment of our operations with your health insurance. We will say
          “yes” unless a law requires us to share that information.
        </li>
      </ul>
      <h3>Get a list of those with whom we’ve shared information</h3>
      <ul>
        <li>
          You can ask for a list (accounting) of the times we’ve shared your
          health information for six years prior to the date you ask, who we
          shared it with, and why.
        </li>
        <li>
          We will include all the disclosures except for those about treatment,
          payment, and health care operations, and certain other disclosures
          (such as any you asked us to make). We’ll provide one accounting a
          year for free but will charge a reasonable, cost-based fee if you ask
          for another one within 12 months.
        </li>
      </ul>
      <h3>Get a copy of this privacy notice</h3>
      <ul>
        <li>
          You can ask for a paper copy of this notice at any time even if you
          have agreed to receive the notice electronically.
        </li>
        <li>We will provide you with a paper copy promptly.</li>
      </ul>
      <h3>Choose someone to act for you</h3>
      <ul>
        <li>
          If you have given someone medical power of attorney, have activated
          health care proxy, or if someone is your legal guardian, that person
          can exercise your rights and make choices about your health care
          information.
        </li>
        <li>
          We will make sure the person has this authority and can act for you
          before we take any action.
        </li>
      </ul>
      <h3>File a complaint if you feel your rights are violated</h3>
      <ul>
        <li>
          You can complain if you feel we have violated your rights by
          contacting us using the information at the end of this Notice.
        </li>
        <li>
          You can file a complaint with the U.S. Department of Health and Human
          Services Office for Civil Rights by sending a letter to 200
          Independence Avenue, S.W., Washington D.C. 20201, calling
          1-877-696-6755, or visiting{" "}
          <a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html">
            https://www.hhs.gov/hipaa/filing-a-complaint/index.html
          </a>
        </li>

        <li>We will not retaliate against you for filing a complaint.</li>
      </ul>
      <h2>YOUR CHOICES</h2>
      <p>
        For certain health information, you can tell us your choices about what
        we share. If you have a clear preference for how we share your
        information in the situations described below, talk to us. Tell us what
        you want us to do, and we will follow your instructions.
      </p>
      <p>
        In these cases you have both the right and the choice to tell us whether
        or not to:
      </p>
      <ul>
        <li>
          Share information with your family, close friends, or others involved
          in your care.
        </li>
        <li>Share information in a disaster relief situation.</li>
        <li>Include your information in a hospital directory.</li>
      </ul>
      <p>
        If you (or someone empowered to act on your behalf) are not able to tell
        us your preference, for example if you are unconscious and have no
        health care proxy, we may go ahead and share your information if we
        believe it is in your best interest. We may also share your information
        when needed to lessen a serious and imminent threat to health or safety.
      </p>
      <p>
        In these cases, we never share your information unless you give us
        written permission:
      </p>
      <ul>
        <li>Marketing purposes.</li>
        <li>Sale of your information.</li>
        <li>Most sharing of physical therapy notes.</li>
        <li>Substance abuse treatment records protected by 42 CFR Part 2.</li>
        <li>HIV/AIDS testing or test results.</li>
        <li>Certain genetic information.</li>
        <li>Certain information about sexually transmitted diseases.</li>
      </ul>
      <p>In the case of fundraising:</p>
      <ul>
        <li>
          We may contact you for fundraising efforts, but you can tell us not to
          contact you again.
        </li>
      </ul>
      <h2>OUR USES AND DISCLOSURES</h2>
      <p>
        How do we typically use or share your health information? We typically
        use or share your health information in the following ways.
      </p>
      <p>
        TREAT YOU: We can use your health information and share it with other
        professionals who are treating you.
      </p>
      <p>
        RUN OUR ORGANIZATION: We can use and share your health information to
        run our operations, improve your care, and contact you when necessary.
      </p>
      <p>
        BILL FOR YOUR SERVICES: We can use and share your health information to
        bill and get payment from health plans or other entities.
      </p>
      <h3>HOW ELSE CAN WE USE OR SHARE YOUR HEALTH INFORMATION?</h3>
      <p>
        We are allowed or required to share your information in other ways-
        usually in ways that contribute to the public good, such as public
        health and research. We have to meet many conditions in the law before
        we can share your information for these purposes.
      </p>
      <h3>Help with public and safety issues</h3>
      <p>
        We can share health information about you for certain situations such
        as:
      </p>
      <ul>
        <li>Preventing disease.</li>
        <li>Helping with product recalls.</li>
        <li>Reporting adverse reactions to medications.</li>
        <li>Reporting suspected abuse, neglect, or domestic violence.</li>
        <li>
          Preventing or reducing a serious threat to anyone’s health or safety.
        </li>
      </ul>
      <h3>Do research</h3>
      <p>
        Depending on the circumstances, we can use or share your information for
        health research.
      </p>
      <h3>Comply with the law</h3>
      <p>
        We will share information about you if state or federal laws require it,
        including with the Department of Health and Human Services if it wants
        to see that we’re complying with federal privacy law.
      </p>
      <h3>Respond to organ and tissue donation requests</h3>
      <p>
        We can share health information about you with organ procurement
        organizations.
      </p>
      <h3>Work with a medical examiner or funeral director</h3>
      <p>
        We can share health information with a coroner, medical examiner, or
        funeral director when an individual dies.
      </p>
      <h3>
        Address workers’ compensation, law enforcement, and other government
        requests
      </h3>
      <p>We can use or share health information about you:</p>
      <ul>
        <li>For workers’ compensation claims.</li>
        <li>
          For law enforcement purposes or with a law enforcement official.
        </li>
        <li>
          With health oversight agencies for activities authorized by law.
        </li>
        <li>
          For special government functions such as military national security,
          and presidential protective services.
        </li>
      </ul>
      <h3>Respond to lawsuits and legal actions</h3>
      <p>
        We can share health information about you in response to a court or
        administrative order, or in response to a subpoena.
      </p>
      <h2>OUR RESPONSIBILITIES</h2>
      <ul>
        <li>
          We are required by law to maintain the privacy and security of your
          protected health information.
        </li>
        <li>
          We will let you know promptly if a breach occurs that may have
          compromised the privacy or security of your information.
        </li>
        <li>
          We must follow the duties and privacy practices described in this
          notice and give you a copy of it.
        </li>
        <li>
          We will not use or share your information other than as described here
          unless you tell us we can in writing. If you tell us we can, you may
          change your mind at any time. Let us know in writing if you change
          your mind.
        </li>
      </ul>
      <h3>CHANGES TO THE TERMS OF THIS NOTICE</h3>
      <p>
        We can change the terms of this Notice, and the changes will apply to
        all information we have about you. The new notice will be available upon
        request at any entity listed in this Notice and on our website at{" "}
        <a href="https://www.jointworkspt.com/privacy-practices">
        https://www.jointworkspt.com/privacy-practices
        </a>
        .
      </p>
      <h3>Contact Person for this Notice</h3>
      <p>
        If you have any questions about this Notice or a complaint about our
        privacy practices, please contact our Privacy Officer at:
      </p>
      <address>
        Steven Lagasse
        <br />
        440 Benson Road, Northbridge, MA 01534
        <br />
        Phone: (774) 246-6440
        <br />
        Email:{" "}
        <a href="mailto:Dr.Lagasse@JointWorksPT.com">
          Dr.Lagasse@JointWorksPT.com
        </a>
        <br />
      </address>
      <p>
        <strong>EFFECTIVE DATE OF NOTICE:</strong> October 1st, 2023
      </p>
    </div>
  );
};

export default PrivacyPractices;
