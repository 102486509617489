import React from "react";
import "./LegalFooter.css"; // Importing the CSS

const LegalFooter = () => {
  return (
    <footer className="additional-footer">
      <ul>
        <li>
          <a href="/new-patient-portal">New Patient Portal</a>
        </li>
        <li>
          <a href="/privacy-practices">Notice of Privacy Practices</a>
        </li>
        <li>
          <a href="/no-surprises-act">No Surprises Act</a>
        </li>
        {/* Add more anchor elements as needed */}
      </ul>
    </footer>
  );
};

export default LegalFooter;
