import React from "react";

import TestimonialList from "../components/TestimonialList";
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <>
      <h1>Testimonials</h1>
      <div className="taper-body-info">
        <TestimonialList />
        <div className="center-item-container">
          <a
            className="body-button margin-top"
            href="https://jointworkspt.janeapp.com"
          >
            Book an Appointment
          </a>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
