import React from "react";
import "./Services.css";
import Needling from "../images/Adjunct.jpg";
import Manual from "../images/manualtherapy.png";
import Therex from "../images/Therex.jpg";
import HomePT from "../images/homept.png";
import BFR from "../images/BFR.png";
import Cupping from "../images/Cupping.png";
import SoftTissue from "../images/SoftTissue.png";

const Services = () => {
  const services = [
    {
      title: "In-Home & Telehealth Options",
      description:
        "JointWorks brings physical therapy to you. Whether in your home or through a virtual session, receive expert care where you feel most comfortable. We're here to make recovery convenient and easy for you.",
      infoLink: "/in-home-telehealth-info",
      image: HomePT,
    },
    {
      title: "Joint Mobilization and Manipulation",
      description:
        "We excel in advanced joint mobilization and manipulation techniques. We use precise hands-on methods to address specific concerns, promoting targeted healing within your body.",
      infoLink: "/joint-mobilization-info",
      image: Manual,
    },
    {
      title: "Dry Needling",
      description:
        "Dry Needling involves inserting thin needles into specific trigger points in muscles, helping to alleviate pain and improve muscle function.",
      infoLink: "/dry-needling-info",
      image: Needling,
    },
    {
      title: "Therapeutic Exercise",
      description:
        "Exercise is key at JointWorks. We prescribe specialized Therapeutic Exercises to help you get stronger, move easier, and feel better.",
      infoLink: "/therapeutic-exercise-info",
      image: Therex,
    },
    {
      title: "Soft Tissue Mobilization",
      description:
        "Experience the benefits of Soft Tissue Mobilization techniques to address soft tissue restrictions, promoting flexibility and reducing discomfort. Let us guide you on the path to improved mobility and overall well-being.",
      infoLink: "/soft-tissue-mobilization-info",
      image: SoftTissue,
    },
    {
      title: "Blood Flow Restriction Training",
      description:
        "Blood Flow Restriction Training involves using cuffs to partially restrict blood flow during exercise, enhancing muscle growth and strength with lower resistance.",
      infoLink: "/bfr-training-info",
      image: BFR,
    },

    {
      title: "Functional Cupping",
      description:
        "Functional Cupping uses suction cups to create a vacuum effect, promoting blood flow and reducing muscle tension for improved mobility and pain relief.",
      infoLink: "/functional-cupping-info",
      image: Cupping,
    },
  ];

  return (
    <>
      <h1>What We Offer</h1>

      <div className="container taper-body-info">
        <div className="dashed-border">
          <h2 className="emphasize">Free Consultation</h2>
          <p>
            At JointWorks, we make it a point to understand everyone&apos;s
            unique pain. To ensure we can best assist you, we offer a free phone
            or telehealth consultation to ensure we can best assist you!
          </p>
          <a className="body-button" href="https://jointworkspt.janeapp.com">
            Request Consultation
          </a>
        </div>

        {services.map((service, index) => (
          <div key={index}>
            <h2>{service.title}</h2>
            <img alt="" src={service.image} />
            <p>
              {service.description}
              <span
                // className="info-icon"
                onClick={() => (window.location.href = service.infoLink)}
              ></span>
            </p>
          </div>
        ))}
        <br />
        <a className="body-button" href="https://jointworkspt.janeapp.com">
          Book an Appointment
        </a>
      </div>
    </>
  );
};

export default Services;
