import React from "react";

import "./Sidebar.css";

const Sidebar = ({ isOpen }) => (
  <div className="sidebar" style={{ display: isOpen ? "block" : "none" }}>
    <ul>
      <li>
        <a href="/">Home</a>
      </li>
      <li>
        <a href="/about">Dr. Lagasse</a>
      </li>
      <li>
        <a href="/services">Services</a>
      </li>
      <li>
        <a href="/insurance">Pricing & Insurance</a>
      </li>
      <li>
        <a href="/testimonials">Testimonials</a>
      </li>
      {/* <li>
        <a href="/payment">Pay Here</a>
      </li> */}
      <li>
        <a href="/contact">Contact Us</a>
      </li>
    </ul>
  </div>
);

export default Sidebar;
