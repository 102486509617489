import React from 'react'

import clinic from '../images/clinic.jpg'
import './Home.css'

const Home = () => {
  return (
    <>
      <h1>Welcome to JointWorks Physical Therapy!</h1>

      <div className="center-item-container">
        <img alt="" className='insurance-img' src={clinic} />
      </div>
      <div className="taper-body-info">
        <div className="paragraph-container">
          <p>
            We offer a wide range of proven physical therapy services to help
            those in need.
          </p>
          <p>
            At JointWorks, your well-being comes first. We offer direct access,
            which means you can get care quickly, without needing a referral
            from a doctor. As an out-of-network, self-pay practice, we can fully
            concentrate on treating you, the patient, without any constraints
            from insurance companies. Get personalized care that isn&apos;t delayed
            or denied.
          </p>
          <p>
            Take a look around our website, and think about setting up a free
            consult with Dr. Steven Lagasse, our skilled physical therapist.
          </p>
        </div>

        <div className="center-item-container">
          <h3 className="underline-text">
            Your journey to optimal function starts here!
          </h3>
        </div>
        <br />
        <div className="center-item-container">
          <a className="body-button" href="/about">
            Learn More!
          </a>
        </div>
      </div>
    </>
  )
}

export default Home
