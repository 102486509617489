import React, { useState, useRef, useEffect } from "react";

import HamburgerIcon from "./HamburgerIcon";
import Sidebar from "./Sidebar";
import logo from "../images/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [hamburgerToggle, setToggleHamburger] = useState(false);
  const navRef = useRef(null);

  const toggleHamburger = () => {
    setToggleHamburger(!hamburgerToggle);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setToggleHamburger(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  return (
    <div ref={navRef}>
      <div className="nav-header">
        <div className="logo-wrapper nav1">
          <a href="http://jointworkspt.com/">
            <img alt="" className="nav-logo" src={logo} />
          </a>
        </div>
        <div className="button-wrapper nav2">
          <a className="body-button" href="https://jointworkspt.janeapp.com">
          Book an Appointment
          </a>
        </div>
        <div className="nav3">
          <HamburgerIcon toggle={toggleHamburger} />
          <div className="sidebar-wrapper ">
            <Sidebar isOpen={hamburgerToggle} />
          </div>
        </div>
      </div>
      <nav className="navbar">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">Dr. Lagasse</a>
          </li>
          <li>
            <a href="/services">Services</a>
          </li>
          <li>
            <a href="/insurance">Pricing & Insurance</a>
          </li>
          <li>
            <a href="/testimonials">Testimonials</a>
          </li>
          {/* <li>
            <a href="/payment">Pay Here</a>
          </li> */}
          <li>
            <a href="/contact">Contact Us</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
