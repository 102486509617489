import React from 'react'

import './SocialMedia.css'

import greenFacebook from '../images/social-media/green-facebook.png'
import greenInstagram from '../images/social-media/green-instagram.png'
import greenTwitter from '../images/social-media/green-twitter.png'
import greenYoutube from '../images/social-media/green-youtube.png'
import blueFacebook from '../images/social-media/blue-facebook.png'
import blueInstagram from '../images/social-media/blue-instagram.png'
import blueTwitter from '../images/social-media/blue-twitter.png'
import blueYoutube from '../images/social-media/blue-youtube.png'

const SocialMedia = () => {
  return (
    <div className="social-media-container">
      <a className="social-media-icon" href="https://facebook.com/jointworkspt">
        <img alt="" src={greenFacebook} />
        <img alt="" src={blueFacebook} />
      </a>
      <a
        className="social-media-icon"
        href="https://instagram.com/jointworkspt"
      >
        <img alt="" src={greenInstagram} />
        <img alt="" src={blueInstagram} />
      </a>
      <a className="social-media-icon" href="https://twitter.com/jointworkspt">
        <img alt="" src={greenTwitter} />
        <img alt="" src={blueTwitter} />
      </a>
      <a className="social-media-icon" href="https://youtube.com/@jointworkspt">
        <img alt="" src={greenYoutube} />
        <img alt="" src={blueYoutube} />
      </a>
    </div>
  )
}

export default SocialMedia
