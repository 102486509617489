import React from "react";
import SocialMedia from "./SocialMedia";
import LegalFooter from "./LegalFooter";
import location from "../images/vectors/location.png";
import bellatorFitness from "../images/Bellator-Fitness.png";
import JTB from "../images/JTB.png";
import "./PreFooter.css";

const PreFooter = () => {
  return (
    <div className="prefooter">
      <div className="pf1">
        {/* Original Bellator Fitness section */}
        <div className="address-and-logo-container">
          <div className="address-container">
            <div className="location-container">
              <img alt="" className="location-icon" src={location} />
              <h3 className="underline-text">Located Inside:</h3>
            </div>
            <span>1047 Providence Rd,</span>
            <span>Whitinsville, MA 01588</span>
          </div>
          <a href="https://bellatorfitnessxxiv.com/">
            <img
              alt=""
              className="bellator-fitness-icon"
              src={bellatorFitness}
            />
          </a>
        </div>
        <br />
        {/* Duplicated Bellator Fitness section */}
        <div className="address-and-logo-container">
          <div className="address-container">
            <div className="location-container">
              <img alt="" className="location-icon" src={location} />
              <h3 className="underline-text">Partnered with:</h3>
            </div>
            <span>27 Mendon St,</span>
            <span>Uxbridge, MA 01569</span>
          </div>
          <a href="https://jtbwellness.com/">
            <img alt="" className="bellator-fitness-icon" src={JTB} />
          </a>
        </div>
        <br />
      </div>

      <div className="pf2">
        <SocialMedia />
        <br />
        <LegalFooter />
      </div>
    </div>
  );
};

export default PreFooter;
