import React from "react";

import ContactForm from "../components/ContactForm";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
      <div className="taper-body-info">
        <p>
          Choosing a physical therapist is an important decision. At JointWorks,
          we aim to make this decision as simple as possible for you. If
          you&apos;re interested in a free consult and movement screen, have any
          questions about the information on our site, or wish to discuss your
          specific needs, please feel free to reach out. You can contact us via
          the form, email, or phone.
        </p>
        <div>
          <div className="contact-container">
            <h3>Located Inside Bellator Fitness:</h3>
            <p className="para-padding">
              1047 Providence Rd, Whitinsville, MA 01588
            </p>
          </div>
          <div className="contact-container">
            <h3>Email:</h3>
            <p className="para-padding">Dr.Lagasse@JointWorksPT.com</p>
          </div>
          <div className="contact-container">
            <h3>Call or Text:</h3>
            <p className="para-padding">(774) 246-6440</p>
          </div>
          <br />
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact;
